import { Component, OnInit } from "@angular/core";
import {
  trigger,
  transition,
  animate,
  style,
  state,
} from "@angular/animations";
import { HttpService } from "app/services/http.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  animations: [
    trigger("menuToggle", [
      transition(":enter", [
        style({ transform: "translateX(-100%)", opacity: 0 }),
        animate(
          "300ms ease-in",
          style({ transform: "translateX(0%)", opacity: 1 })
        ),
      ]),

      transition(":leave", [
        style({ transform: "translateX(0%)", opacity: 1 }),
        animate(
          "300ms ease-in",
          style({ transform: "translateX(100%)", opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  public show: boolean = false;
  public dataContentFace = [];
  public dataContentPhone = [];
  public faceLink: string = "";
  public phoneNumber: string = "";

  constructor(private httpService: HttpService) {}

  public toggleMenuMobile() {
    this.show = !this.show;
  }

  public disabledTag(event) {
    event.preventDefault();
  }
  ngOnInit() {
    this.httpService.getPageContent("TTC").subscribe((res) => {
      this.dataContentFace = res.data.TTC.filter((element) => {
        return element.page_code === "TT-4";
      });
      this.dataContentPhone = res.data.TTC.filter((element) => {
        return element.page_code === "TT-2";
      });
      this.faceLink = this.dataContentFace[0].page_content
        .replace(/<[\/]{0,1}(p)[^><]*>/gi, "")
        .replace(/<[\/]{0,1}(br)[^><]*>/gi, "");
      this.phoneNumber = this.dataContentPhone[0].page_content;
    });
  }
}
