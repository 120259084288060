import { Routes } from "@angular/router";

import { BlankLayoutComponent } from "app/layouts/blank-layout/blank-layout.component";
import { FullLayoutComponent } from "app/layouts/full-layout/full-layout.component";
import { SimpleLayoutComponent } from "app/layouts/simple-layout/simple-layout.component";

export const routing: Routes = [
  { path: "", redirectTo: "/", pathMatch: "full" },
  {
    path: "",
    component: FullLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: "app/pages/home/home.module#HomeModule",
      },
    ],
  },
  {
    path: "",
    component: BlankLayoutComponent,
    children: [
      {
        path: "than-so/customer/:id",
        loadChildren: "app/pages/than-so/than-so.module#ThanSoModule",
      },
    ],
  },
  {
    path: "",
    component: FullLayoutComponent,
    children: [
      {
        path: "giai-ma",
        loadChildren:
          "app/pages/search-result/search-result.module#SearchResultModule",
      },
    ],
  },
  {
    path: "**",
    loadChildren: "app/pages/error/not-found/not-found.module#NotFoundModule",
  },
];
